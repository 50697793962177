<template>
  <div class="container mt-5">
  <form @submit.prevent="sendMessage">
    <div class="form-group">
      <textarea class="form-control" v-model="message" rows="5" placeholder="What's your mind"></textarea>
    </div>
    <input type="submit" class="btn btn-primary" value="Send">
  </form>
  <br>
  <ul class="list-group list-group-flush">
    <li class="list-group-item" v-for="message in messages" :key="message.id">
      <p style="white-space : break-spaces;">{{message.message}}</p>
      <small class="d-block text-right">Send by {{message.sender}}</small> 
    </li>
  </ul>
  </div>
</template>

<script>


export default {
  name: 'App',
  data(){
    return {
      messages  : [],
      message   : '',
      sender    : '',
    }
  },
  methods : {
    sendCheck(){
      if(this.sender == ""){
        var data = prompt("Enter your name");
        this.sender = data;
      }
    },
    sendMessage(){
      this.$socket.emit("send", ({ message : this.message, sender : this.sender}));
      this.message = '';
    }
  },
  mounted(){
    this.sendCheck();
    this.$socket.on('update', (data) => {
      this.messages = data;
    })
  },
  components: {
    
  }
}
</script>

<style>
</style>
