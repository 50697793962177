import { createApp } from 'vue';
import App from './App.vue';
import moment from 'moment';
import io from 'socket.io-client';


const socket = io(`https://live.bluebelltex.com`);

const app = createApp(App);
app.config.globalProperties.$moment = moment;
app.config.globalProperties.$socket = socket;

app.mount('#app');